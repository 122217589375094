import React, { useEffect, useState } from "react";
import DataAnalytics from "./subcourses/DataAnalytics";

const Courses = () => {
  const getCurrentCourse = localStorage.getItem("currentCourse");
  const [activeCourse, setActiveCourse] = useState(0);

  useEffect(() => {
    getCurrentCourse === "0" ? setActiveCourse(0) : setActiveCourse(0);
  }, [getCurrentCourse]);

  const coursesArr = [
    {
      title: "Data Analytics",
      component: <DataAnalytics />,
    },
  ];

  return (
    <div>
      <p className="text-[32px] text-center max-sm:text-[24px] font-medium mb-8 max-sm:mb-4 text-mineShaft">
        Courses
      </p>
      <div className="flex gap-12 justify-center items-center overflow-x-auto mb-12 max-md:mb-6">
        <div className="flex items-center gap-2 bg-wildSand py-1 px-2 rounded-[48px]">
          {coursesArr.map((link, idx) => (
            <div
              key={idx}
              onClick={() => setActiveCourse(idx)}
              className={`cursor-pointer ${
                activeCourse === idx && "bg-elm text-white"
              } rounded-[48px] px-8 py-3 flex items-center justify-center`}
            >
              <p className="text-[16px] max-md:text-[12px] pb-1 whitespace-nowrap">
                {link.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>{coursesArr[activeCourse].component}</div>
    </div>
  );
};

export default Courses;
