import React, { useEffect, useState } from "react";
import Logo from "shared/Logo";
import { TWAGreenArrowRightIcon, TWAIconMenu } from "assets/svgs";
import Design from "./components/Design";
import Engineering from "./components/Engineering";
import DataComp from "./components/DataComp";
import { useLocation } from "react-router-dom";

const ProgramsPageContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [onHover, setOnHover] = useState(false);
  const location = useLocation();

  useEffect(() => {
    location?.state?.type === "engineering"
      ? setActiveTab(1)
      : location?.state?.type === "data"
      ? setActiveTab(2)
      : setActiveTab(0);
  }, [location?.state]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const programsTab = [
    { title: "Our Design Programs", component: <Design /> },
    { title: "Our Engineering Programs", component: <Engineering /> },
    { title: "Our Data Programs", component: <DataComp /> },
  ];

  return (
    <div>
      <div className="bg-white z-50 py-8 max-sm:pt-8 max-sm:pb-4 px-24 max-md:px-8 sticky top-0 w-full flex justify-between items-center mb-6">
        <div className="w-2/5">
          <Logo inverted />
        </div>
        <div className="w-full flex justify-between gap-3 max-[930px]:hidden">
          {programsTab.map((link, idx) => (
            <div
              key={idx}
              onClick={() => setActiveTab(idx)}
              className="cursor-pointer"
            >
              <p
                className={`${
                  activeTab === idx && "text-elm"
                } text-[20px] pb-1 whitespace-nowrap`}
              >
                {link.title}
              </p>
              {activeTab === idx && (
                <div className="w-full border-b-2 border-elm"></div>
              )}
            </div>
          ))}
        </div>
        <div className="relative min-[930px]:hidden">
          <TWAIconMenu onClick={() => setOnHover(true)} />
          <div
            className={`absolute top-[45px] right-0 text-black bg-white shadow-lg p-12 max-sm:py-6 px-6 ${
              onHover ? "block" : "hidden"
            }`}
          >
            <div
              onClick={() => setOnHover(false)}
              className="fixed inset-0 -z-10 cursor-pointer"
            ></div>
            {programsTab.map((schoolProgram, idx) => (
              <div
                key={idx}
                onClick={() => {
                  setOnHover(false);
                  setActiveTab(idx);
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                className="cursor-pointer w-[calc(100vw-180px)] max-md:w-[calc(100vw-100px)] pb-4 mb-6 border-[#B4AEAE] border-b"
              >
                <div className="w-full flex justify-between items-center">
                  <p className="text-[16px] max-sm:text-[12px] whitespace-nowrap">
                    {schoolProgram.title}
                  </p>
                  <TWAGreenArrowRightIcon className="max-sm:w-4 max-sm:h-4" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pb-8 px-24 max-md:px-8">
        {programsTab[activeTab].component}
      </div>
    </div>
  );
};

export default ProgramsPageContent;
