import React, { useEffect, useRef, useState } from "react";
import { choiceOfPrograms, countryList, waitlistImages } from "utils/data";
import Logo from "shared/Logo";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Success from "./Success";
import { useLocation } from "react-router-dom";
import { joinWaitlist } from "api/join-waitlist";

const JoinWaitlistContents = ({ isVisible, elemRef }) => {
  const location = useLocation();
  const [projectIdx, setProjectIdx] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = () => {
    if (currentStep === 0) {
      return setCurrentStep((prev) => prev + 1);
    }
  };
  const timeoutRef = useRef(null);

  const delay = 12000;

  const handleClick = (index) => {
    setProjectIdx(index);
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setProjectIdx((prevIndex) =>
          prevIndex === waitlistImages.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [projectIdx]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleJoinWaitlist = async (body) => {
    setIsLoading(true);
    try {
      const { status } = await joinWaitlist(body);
      if (status === 200) {
        toast.success(`Successfully joined waitlist!`);
        handleNextStep();
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      country: "",
      email: "",
      mobile: "",
      program:
        location?.state?.course === "User Experience Interface Design"
          ? "User Experience Interface Design"
          : location?.state?.course === "Graphics Design"
          ? "Graphics Design"
          : location?.state?.course === "Frontend Web Development"
          ? "Frontend Web Development"
          : location?.state?.course === "Backend Web Development"
          ? "Backend Web Development"
          : location?.state?.course === "Mobile App Development"
          ? "Mobile App Development"
          : location?.state?.course === "Data Analytics"
          ? "Data Analytics"
          : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("invalid Email")
        .required("This field is Required"),
      country: Yup.string().required("This field is required"),
      gender: Yup.string().required("This field is required"),
      program: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        country: values.country,
        gender: values.gender,
        program: values.program,
      };
      handleJoinWaitlist(body);
    },
  });

  const steps = [
    <div
      ref={elemRef}
      className={`${
        isVisible && "animate__animated animate__zoomIn"
      } flex pt-16 max-[1000px]:pb-16 pl-8 gap-8 max-[1000px]:px-12 max-md:px-6`}
    >
      <div className="w-2/5 max-[1000px]:w-full">
        <div className="mb-8">
          <Logo />
        </div>
        <p className="text-[24px] max-md:hidden mb-8">
          Apply To Techwave Academy
        </p>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="flex gap-3">
            <div className="w-1/2">
              <div className="w-full flex flex-col gap-2">
                <label className="text-[14px]">First Name</label>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  name="firstName"
                  type="text"
                  className="w-full rounded-lg border px-4 min-h-[65px] border-[#CCC] focus:outline-none placeholder:text-[12px]"
                />
              </div>
              {formik.touched.firstName && formik.errors.firstName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.firstName}
                </p>
              ) : null}
            </div>
            <div className="w-1/2">
              <div className="w-full flex flex-col gap-2">
                <label className="text-[14px]">Last Name</label>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name="lastName"
                  type="text"
                  className="w-full rounded-lg border px-4 min-h-[65px] border-[#CCC] focus:outline-none placeholder:text-[12px]"
                />
              </div>
              {formik.touched.lastName && formik.errors.lastName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.lastName}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-1/2">
              <div className="w-full flex flex-col gap-2">
                <label className="text-[14px]">Gender</label>
                <div className="relative w-full flex items-center justify-center">
                  <select
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                    name="gender"
                    className={`appearance-none flex items-center justify-center w-full h-[65px] px-4 text-sm border border-[#CCC] rounded-lg outline-none focus:outline-none`}
                  >
                    {[
                      { title: "--Select gender--", value: "" },
                      { title: "Male", value: "male" },
                      { title: "Female", value: "female" },
                    ].map((option, index) => (
                      <option
                        className="capitalize"
                        key={index}
                        value={option.value}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[2px] pointer-events-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16.8C11.3 16.8 10.6 16.53 10.07 16L3.54999 9.48001C3.25999 9.19001 3.25999 8.71001 3.54999 8.42001C3.83999 8.13001 4.31999 8.13001 4.60999 8.42001L11.13 14.94C11.61 15.42 12.39 15.42 12.87 14.94L19.39 8.42001C19.68 8.13001 20.16 8.13001 20.45 8.42001C20.74 8.71001 20.74 9.19001 20.45 9.48001L13.93 16C13.4 16.53 12.7 16.8 12 16.8Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {formik.touched.gender && formik.errors.gender ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.gender}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="w-1/2">
              <div className="w-full flex flex-col gap-2">
                <label className="text-[14px]">Country</label>
                <div className="relative w-full flex items-center justify-center">
                  <select
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    name="country"
                    className={`appearance-none flex items-center justify-center w-full h-[65px] px-4 text-sm border border-[#CCC] rounded-lg outline-none focus:outline-none`}
                  >
                    <option className="capitalize" value={""}>
                      --Select Country--
                    </option>
                    {countryList.map((option, index) => (
                      <option
                        className="capitalize"
                        key={index}
                        value={option.name}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[2px] pointer-events-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16.8C11.3 16.8 10.6 16.53 10.07 16L3.54999 9.48001C3.25999 9.19001 3.25999 8.71001 3.54999 8.42001C3.83999 8.13001 4.31999 8.13001 4.60999 8.42001L11.13 14.94C11.61 15.42 12.39 15.42 12.87 14.94L19.39 8.42001C19.68 8.13001 20.16 8.13001 20.45 8.42001C20.74 8.71001 20.74 9.19001 20.45 9.48001L13.93 16C13.4 16.53 12.7 16.8 12 16.8Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {formik.touched.country && formik.errors.country ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.country}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-full flex flex-col gap-2">
              <label className="text-[14px]">Email</label>
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                type="email"
                className="w-full rounded-lg border px-4 min-h-[65px] border-[#CCC] focus:outline-none placeholder:text-[12px]"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex gap-3 mb-8">
            <div className="w-full flex flex-col gap-2">
              <label className="text-[14px]">Choice of Program</label>
              <div className="relative w-full flex items-center justify-center">
                <select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.program}
                  name="program"
                  className={`appearance-none flex items-center justify-center w-full h-[65px] px-4 text-sm border border-[#CCC] rounded-lg outline-none focus:outline-none`}
                >
                  {[
                    { title: "--Select program--", value: "" },
                    ...choiceOfPrograms,
                  ].map((option, index) => (
                    <option
                      className="capitalize"
                      key={index}
                      value={option.value}
                    >
                      {option.title}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[2px] pointer-events-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16.8C11.3 16.8 10.6 16.53 10.07 16L3.54999 9.48001C3.25999 9.19001 3.25999 8.71001 3.54999 8.42001C3.83999 8.13001 4.31999 8.13001 4.60999 8.42001L11.13 14.94C11.61 15.42 12.39 15.42 12.87 14.94L19.39 8.42001C19.68 8.13001 20.16 8.13001 20.45 8.42001C20.74 8.71001 20.74 9.19001 20.45 9.48001L13.93 16C13.4 16.53 12.7 16.8 12 16.8Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              {formik.touched.program && formik.errors.program ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.program}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-full">
            <button type="submit" className="w-full">
              <div className="w-full max-sm:h-[50px] bg-elm rounded-lg flex justify-center items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
                <p className="text-[16px] max-sm:text-[12px] text-white">
                  {isLoading ? "Loading..." : "Proceed"}
                </p>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div
        className={`w-3/5 max-[1000px]:hidden rounded-tl-[82px] rounded-br-[40px] overflow-hidden max-w-full mt-0 mx-auto`}
      >
        <div
          className="whitespace-nowrap"
          style={{
            transform: `translate3d(${-projectIdx * 100}%, 0, 0)`,
            transition: "ease 1000ms",
          }}
        >
          {waitlistImages.map((project, idx) => (
            <div
              key={idx}
              className="relative inline-block w-full h-[calc(100vh-82px)] whitespace-normal"
            >
              <div>
                <div>
                  <img
                    className="h-full w-full"
                    src={project.img}
                    alt={`Join our waitlist`}
                  />
                </div>
              </div>
              <div className="absolute bottom-[40px] mx-6">
                <div className="border-2 rounded-lg text-white px-6 py-3  border-white">
                  <p className="text-[20px] mb-2">{project.text}</p>
                  <p className="text-[24px] font-bold">{project.author}</p>
                </div>
                <div className="flex gap-2 justify-center mt-6">
                  {Array.from({ length: 4 }).map((item, index) => (
                    <p
                      onClick={() => handleClick(index)}
                      key={index}
                      className={`${
                        projectIdx === index ? "bg-white" : "bg-transparent"
                      } w-[24px] h-[24px] rounded-full border border-white cursor-pointer`}
                    ></p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    <Success />,
  ];

  return steps[currentStep];
};

export default JoinWaitlistContents;
