import { TWAArrowUpIcon } from "assets/svgs";
import React from "react";
import { Link } from "react-router-dom";

const WaitlistBtn = () => {
  return (
    <div>
      <Link to={"https://learning.techwaveafrica.com/apply"} target="_blank">
        <div className="max-sm:h-[50px] bg-elm rounded-lg flex justify-center items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
          <p className="text-[16px] max-sm:text-[12px] text-white">Register</p>
          <TWAArrowUpIcon className="max-sm:w-4 max-sm:h-4" />
        </div>
      </Link>
    </div>
  );
};

export default WaitlistBtn;
