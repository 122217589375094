import ProgramsPageContent from "pages/programs";
import HomePageContents from "../pages/home";
import JoinWaitlistContents from "pages/joinWaitlist";
import HowItWorksContents from "pages/howItWorks";
import NotFound from "pages/NotFound";
// import AboutContents from "pages/about";

export const routePaths = [
  {
    path: "/",
    element: <HomePageContents />,
  },
  {
    path: "/programs",
    element: <ProgramsPageContent />,
  },
  {
    path: "/join-waitlist",
    element: <JoinWaitlistContents />,
  },
  {
    path: "/",
    element: <HowItWorksContents />,
  },
  // {
  //   path: "/about",
  //   element: <AboutContents />,
  // },
  {
    path: "*",
    element: <NotFound />,
  },
];
