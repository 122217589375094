import { Outlet, Route, Routes } from "react-router-dom";
import { routePaths } from "./routePaths";

const Routing = () => (
  <Routes>
    {routePaths.map((route, index) => {
      return (
        <Route key={`${index}-xxx`} element={<Outlet />}>
          <Route
            key={Math.random()}
            path={route.path}
            element={route.element}
          />
        </Route>
      );
    })}
  </Routes>
);

export default Routing;
