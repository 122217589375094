import Api from "api/api";
import { toast } from "react-toastify";

export const joinWaitlist = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/join-waitlist",
      data: body,
    });
    return { status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
