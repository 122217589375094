import React from "react";
import Banner from "./Banner";
import Courses from "./Courses";

const Engineering = () => {
  return (
    <div>
      <Banner />
      <Courses />
    </div>
  );
};

export default Engineering;
