import { EmojiThumbsUp, SuccessTick } from "assets/images";
import React from "react";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="h-screen bg-success bg-cover bg-no-repeat flex justify-center items-center max-md:px-6">
      <div className="bg-white rounded-lg p-16 max-sm:p-8 max-[500px]:px-4 max-[500px]:py-16 flex flex-col gap-10 justify-around items-center">
        <div className="flex flex-col items-center gap-6">
          <img src={SuccessTick} alt="You have joined the waitlist" />
          <p className="font-clashDisplay text-[32px] font-medium">Done</p>
        </div>
        <div>
          <p className="text-center text-[20px] mb-12">
            You joined the waitlist! Sit up tight we will be in your DMs soon.
          </p>
          <div className="relative flex justify-center">
            <p className="text-center text-[20px]">
              Glad to walk the journey with you.
            </p>
            <img
              className="relative -top-4 max-[500px]:w-[65px] max-[500px]:h-[45px] max-[500px]:-top-2"
              src={EmojiThumbsUp}
              alt="Glad to walk the journey with you."
            />
          </div>
        </div>
        <div>
          <Link to={"/"}>
            <p className="text-elm pb-1 border-b border-elm">Back to home</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
