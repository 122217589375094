import { GoldCrown } from "assets/images";
import React from "react";

const Partnership = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center my-24 max-md:my-12 w-1/2 max-md:w-full max-sm:px-8">
        <div className="relative">
          <p className="text-[32px] max-md:text-[24px] max-sm:text-[14px] font-medium whitespace-nowrap mb-8">
            Join Our First Cohort Starting in January
          </p>
          <img
            className="absolute -right-[40px] -top-[40px] max-sm:h-[45px] max-sm:w-[45px] max-sm:-right-[25px] max-sm:-top-[25px]"
            src={GoldCrown}
            alt="Industry Partnership"
          />
        </div>
        <p className="text-[20px] max-sm:text-[14px] text-center">
          Are you ready to take a leap towards an exciting future? Our inaugural
          cohort is launching this{" "}
          <span className="text-laPalma font-medium py-1 text-[32px] max-lg:text-[26px] max-md:text-[20px] max-sm:text-[16px]">
            JANUARY
          </span>
          , marking the beginning of an incredible journey. Join us now!
        </p>
      </div>
    </div>
  );
};

export default Partnership;
