import React from "react";
import Banner from "./Banner";
import Courses from "./Courses";

const DataComp = () => {
  return (
    <div>
      <Banner />
      <Courses />
    </div>
  );
};

export default DataComp;
