import React, { useEffect, useState } from "react";
import Frontend from "./subcourses/Frontend";
import Backend from "./subcourses/Backend";
import MobileApp from "./subcourses/MobileApp";
import { useLocation } from "react-router-dom";

const Courses = () => {
  const [activeCourse, setActiveCourse] = useState(0);
  const location = useLocation();

  useEffect(() => {
    location?.state?.subtype === "backend"
      ? setActiveCourse(1)
      : location?.state?.subtype === "mobile"
      ? setActiveCourse(2)
      : setActiveCourse(0);
  }, [location?.state?.subtype]);

  const coursesArr = [
    {
      title: "Frontend web Development",
      component: <Frontend />,
    },
    { title: "Backend web Development", component: <Backend /> },
    {
      title: "Mobile App Development",
      component: <MobileApp />,
    },
  ];

  return (
    <div>
      <p className="text-[32px] text-center max-sm:text-[24px] font-medium mb-8 max-sm:mb-4 text-mineShaft">
        Courses
      </p>
      <div className="flex gap-12 justify-center items-center overflow-x-auto mb-12 max-md:mb-6">
        <div className="w-full bg-wildSand rounded-[48px] overflow-x-auto flex items-center gap-2 py-1 px-2">
          {coursesArr.map((link, idx) => (
            <div
              key={idx}
              onClick={() => setActiveCourse(idx)}
              className={`cursor-pointer ${
                activeCourse === idx && "bg-elm text-white"
              } w-full rounded-[48px] px-4 py-3 flex items-center justify-center`}
            >
              <p className="text-[16px] max-md:text-[12px] pb-1 whitespace-nowrap">
                {link.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>{coursesArr[activeCourse].component}</div>
    </div>
  );
};

export default Courses;
