import { LeftHello, Righthello } from "assets/images";
import React from "react";

const Hello = () => {
  return (
    <div className="bg-black py-12 px-16 max-md:px-8 flex flex-col items-center">
      <div className="mb-4 flex items-center gap-1">
        <img
          src={LeftHello}
          alt="Welcome to techwave academy"
          className="max-md:w-[70px]"
        />
        <p className="hello text-[50px] max-md:text-[24px]">Hello</p>
        <img
          src={Righthello}
          alt="Welcome to techwave academy"
          className="max-md:w-[70px]"
        />
      </div>
      <div className="max-[550px]:bg-none bg-boxed-border bg-contain bg-center bg-no-repeat">
        <div className="flex justify-center items-center mt-[10px] h-[420px] max-[1150px]:h-[400px] max-[830px]:h-[300px] max-[630px]:h-[200px] max-[550px]:h-fit">
          <div className="w-3/5 max-[1200px]:w-4/5 max-[550px]:w-full">
            <p className="text-[24px] max-lg:text-[20px] max-md:text-[16px] max-sm:text-[12px] text-center text-white max-[1090px]:leading-[40px] max-[890px]:leading-[30px] leading-[60px]">
              As a student with{" "}
              <span className="bg-geeBung py-1 text-[32px] max-lg:text-[26px] max-md:text-[20px] max-sm:text-[16px]">
                Techwave Academy,
              </span>{" "}
              you will undertake real projects that mirror industry scenarios
              enhancing your problem-solving abilities,{" "}
              <span className="bg-electricViolet py-1 text-[32px] max-lg:text-[26px] max-md:text-[20px] max-sm:text-[16px]">
                teamwork,
              </span>{" "}
              and project management skills that make you become{" "}
              <span className="bg-laPalma py-1 text-[32px] max-lg:text-[26px] max-md:text-[20px] max-sm:text-[16px]">
                employable.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hello;
