import { LearnAnywhereImg } from "assets/images";
import { TWAArrowUpIcon } from "assets/svgs";
import React from "react";
import { Link } from "react-router-dom";

const LearnAnywhere = () => {
  return (
    <div className="flex max-[950px]:flex-col-reverse">
      <div className="w-1/2 max-[950px]:w-full bg-black py-32 max text-white px-28 max-[1200px]:px-16 max-[1200px]:py-24 max-md:py-12 max-md:px-8">
        <div className="flex flex-col items-center gap-6">
          <p className="text-[20px] max-sm:text-[12px]">Learn Virtually!</p>
          <p className="text-[42px] max-sm:text-[20px] font-semibold text-center">
            Learn From anywhere
          </p>
          <p className="text-[16px] max-sm:text-[13px] text-center leading-8 min-sm:leading-10">
            Imagine attending classes, accessing resources, and collaborating
            with peers all while staying connected to your goals and dreams. Our
            virtual classrooms provide a seamless and interactive experience,
            allowing you to engage with instructors and fellow students just as
            you would in a physical setting.
          </p>
          <Link to={"/programs"}>
            <div className="max-sm:h-[40px] bg-elm rounded-lg flex justify-center items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
              <p className="text-[20px] max-sm:text-[12px]">Register Now</p>
              <TWAArrowUpIcon className="max-sm:w-4 max-sm:h-4" />
            </div>
          </Link>
        </div>
      </div>
      <div className="w-1/2 max-[950px]:w-full max-sm:h-[290px] max-[950px]:h-[490px]">
        <img
          src={LearnAnywhereImg}
          alt="Learn virtually"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default LearnAnywhere;
