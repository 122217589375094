import { GraphicsImage } from "assets/images";
import { TWADropdownIcon, TWAIconBlueArrowUp } from "assets/svgs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import WaitlistBtn from "shared/WaitlistBtn";

const GraphicsDesign = () => {
  const [viewDesc, setViewDesc] = useState(true);
  const [select, setSelect] = useState(null);

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  // const handleNavigate = () => {
  //   let path = "/join-waitlist";
  //   navigate(path, {
  //     state: {
  //       course: "Graphics Design",
  //       plan: select,
  //     },
  //   });
  // };

  const toLearnList = [
    {
      title: "Design Principles",
      body: "Master the fundamentals of composition, color theory, typography, and layout design.",
    },
    {
      title: "Visual Branding",
      body: "Discover the art of crafting consistent visual identities for brands and businesses.",
    },
    {
      title: "Digital Illustration",
      body: "Learn to create stunning illustrations and artwork using digital tools.",
    },
    {
      title: "Print & Web Design",
      body: "Explore both print and web design methodologies to create versatile visuals.Explore both print and web design methodologies to create versatile visuals.",
    },
    {
      title: "Design Software Proficiency",
      body: "Gain hands-on experience with industry-standard design software.",
    },
  ];

  return (
    <div>
      <div className="w-4/5 max-md:w-full mb-16 max-md:mb-8">
        <p className="text-[24px] max-md:text-[12px]">
          Students will acquire the expertise to create impactful visual designs
          that communicate ideas effectively.
        </p>
      </div>
      <div className="max-[610px]:flex-wrap flex justify-center mb-12">
        <div className="w-1/2 max-[610px]:w-full pr-24 max-[850px]:pr-6 flex flex-col justify-around gap-8">
          <p className="text-[24px] max-[740px]:text-[12px]">
            Duration: <span className="font-semibold">4 months</span>
          </p>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-persianBlue">Pricing</p>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "monthly"}
                    value={"monthly"}
                    name="monthly-plan"
                  />
                  <p className="text-[20px] max-md:text-[14px] font-medium">
                    <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                      Monthly:
                    </span>{" "}
                    N15,000
                  </p>
                </label>
              </div>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "one-time"}
                    value={"one-time"}
                    name="one-time"
                  />
                  <div className="flex items-center flex-wrap gap-x-4">
                    <p className="text-[20px] whitespace-nowrap max-md:text-[14px] font-medium">
                      <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                        One Time:
                      </span>{" "}
                      N55,000
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <Link
            to={"https://learning.techwaveafrica.com/apply"}
            target="_blank"
            className="w-fit max-md:w-full"
          >
            <WaitlistBtn />
          </Link>
        </div>
        <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8 max-[610px]:hidden">
          <img
            className="w-full h-full object-cover"
            src={GraphicsImage}
            alt="Our graphics design course"
          />
        </div>
      </div>
      <div>
        <div
          onClick={() => setViewDesc(!viewDesc)}
          className="cursor-pointer flex items-center gap-2 mb-8"
        >
          <p className="text-persianBlue text-[24px] max-md:text-[14px]">
            View Course Description
          </p>
          {viewDesc ? (
            <TWAIconBlueArrowUp className="max-md:w-6 max-md:h-6 w-8 h-8" />
          ) : (
            <TWADropdownIcon className="fill-persianBlue stroke-persianBlue max-md:w-6 max-md:h-6 w-8 h-8" />
          )}
        </div>
        <div className={`${viewDesc ? "block" : "hidden"}`}>
          <div className="w-4/5 max-md:w-full mb-8">
            <p className="text-[24px] max-md:text-[14px] font-normal leading-8 min-md:leading-10">
              In this comprehensive course, you'll delve into the essentials of
              graphics design, exploring the tools and techniques that shape
              modern visual storytelling. From conceptualizing ideas to refining
              intricate designs, this program covers every facet of graphics
              design.
            </p>
          </div>
          <div>
            <p className="text-[24px] max-md:text-[14px] mb-4">
              What You'll Learn:
            </p>
            <ul className="list-disc">
              {toLearnList.map((item, idx) => (
                <li key={idx} className="mb-8">
                  <p className="text-[24px] max-md:text-[14px] font-light">
                    <span className="font-medium">{item.title}: </span>
                    {item.body}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 max-md:w-full mb-16">
            <p className="text-[24px] max-md:text-[14px] font-light">
              <span className="font-medium">Outcome: </span>Upon completing this
              course, you'll have the ability to create impactful visual designs
              for both digital and print mediums. Your newfound skills will
              empower you to turn concepts into eye-catching designs that
              captivate audiences and leave a lasting impression.
            </p>
          </div>
          <div className="w-fit mb-8">
            <WaitlistBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicsDesign;
