import { UIUXImage } from "assets/images";
import {
  TWAArrowUpIcon,
  TWADropdownIcon,
  TWAIconBlueArrowUp,
} from "assets/svgs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import WaitlistBtn from "shared/WaitlistBtn";

const UserInterface = () => {
  const [viewDesc, setViewDesc] = useState(true);
  const [select, setSelect] = useState(null);

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  // const handleNavigate = () => {
  //   let path = "/https://learning.techwaveafrica.com/apply";
  //   navigate(path, {
  //     state: {
  //       course: "User Experience Interface Design",
  //       plan: select,
  //     },
  //   });
  // };

  const toLearnList = [
    {
      title: "User-Centered Design",
      body: "Grasp the psychology behind user behavior and learn to design with the user's needs and preferences in mind.",
    },
    {
      title: "Wireframing and Prototyping",
      body: "Create wireframes and interactive prototypes that bring your concepts to life and provide a tangible user experience.",
    },
    {
      title: "Visual Design Principles",
      body: "Learn to harmonize typography, color theory, and layout to craft visually compelling interfaces.",
    },
    {
      title: "Usability Testing",
      body: "Understand the importance of usability testing and how to refine your designs based on user feedback.",
    },
    {
      title: "Design Tools Mastery",
      body: "Gain proficiency in industry-standard design tools used by professionals worldwide.",
    },
  ];

  return (
    <div>
      <div className="w-4/5 max-md:w-full mb-16 max-md:mb-8">
        <p className="text-[24px] max-md:text-[12px]">
          Students will gain the skills to design seamless and user-centric
          digital experiences that captivate and engage users.
        </p>
      </div>
      <div className="max-[610px]:flex-wrap flex justify-center mb-12">
        <div className="w-1/2 max-[610px]:w-full pr-24 max-[850px]:pr-6 flex flex-col justify-around gap-8">
          <p className="text-[24px] max-[740px]:text-[12px]">
            Duration: <span className="font-semibold">4 months</span>
          </p>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-persianBlue">Pricing</p>
              <div className="">
                <label className={`flex items-center gap-3 cursor-pointer`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "monthly"}
                    value={"monthly"}
                    name="monthly-plan"
                  />
                  <p className="text-[20px] max-md:text-[14px] font-medium">
                    <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                      Monthly:
                    </span>{" "}
                    N15,000
                  </p>
                </label>
              </div>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "bi-monthly"}
                    value={"bi-monthly"}
                    name="bimonthly-plan"
                  />
                  <div className="flex items-center flex-wrap gap-x-4">
                    <p className="text-[20px] whitespace-nowrap max-md:text-[14px] font-medium">
                      <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                        One Time:
                      </span>{" "}
                      N55,000
                    </p>
                    <p className="text-[16px] max-md:text-[12px] font-extralight">
                      Get 20% off Discount
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <Link
            to={"https://learning.techwaveafrica.com/apply"}
            target="_blank"
            className="cursor-pointer w-fit max-md:w-full"
          >
            <div className="max-sm:h-[50px] bg-elm rounded-lg flex justify-center items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
              <p className="text-[16px] max-sm:text-[12px] text-white">
                Register
              </p>
              <TWAArrowUpIcon className="max-sm:w-4 max-sm:h-4" />
            </div>
          </Link>
        </div>
        <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8 max-[610px]:hidden">
          <img
            className="w-full h-full object-cover"
            src={UIUXImage}
            alt="Our ui/ux course"
          />
        </div>
      </div>
      <div>
        <div
          onClick={() => setViewDesc(!viewDesc)}
          className="cursor-pointer flex items-center gap-2 mb-8"
        >
          <p className="text-persianBlue text-[24px] max-md:text-[14px]">
            View Course Description
          </p>
          {viewDesc ? (
            <TWAIconBlueArrowUp className="max-md:w-6 max-md:h-6 w-8 h-8" />
          ) : (
            <TWADropdownIcon className="fill-persianBlue stroke-persianBlue max-md:w-6 max-md:h-6 w-8 h-8" />
          )}
        </div>
        <div className={`${viewDesc ? "block" : "hidden"}`}>
          <div className="w-4/5 max-md:w-full mb-8">
            <p className="text-[24px] max-md:text-[14px] font-normal leading-8 min-md:leading-10">
              In this comprehensive course, you'll delve into the world of User
              Interface (UI) and User Experience (UX) design, discovering the
              principles and techniques that drive successful digital
              interactions. From understanding user behavior to translating
              ideas into intuitive interfaces, this program covers every facet
              of the design process.
            </p>
          </div>
          <div>
            <p className="text-[24px] max-md:text-[14px] mb-4">
              What You'll Learn:
            </p>
            <ul className="list-disc">
              {toLearnList.map((item, idx) => (
                <li key={idx} className="mb-8">
                  <p className="text-[24px] max-md:text-[14px] font-light">
                    <span className="font-medium">{item.title}: </span>
                    {item.body}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 max-md:w-full mb-16">
            <p className="text-[24px] max-md:text-[14px] font-light">
              <span className="font-medium">Outcome: </span>Upon completion of
              this course, you'll have the skills and confidence to design
              user-centered interfaces, wireframes, and prototypes. Your journey
              will culminate in the creation of a portfolio that showcases your
              mastery of UI/UX design, setting you on a path towards a rewarding
              career in the digital design landscape.
            </p>
          </div>
          <div className="w-fit mb-8">
            <WaitlistBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInterface;
