import { learnData } from "utils/data";

const HowToLearn = () => {
  return (
    <div className="howToLearn py-8 px-16 max-md:px-6">
      <div className="flex justify-center mb-6">
        <p className="text-white font-clashDisplay text-[72px] max-lg:text-[62px] max-md:text-[50px] max-sm:text-[32px] font-semibold">
          How You Will Learn
        </p>
      </div>
      <div className="flex flex-col gap-16">
        {learnData.map((item) => (
          <div
            key={item.id}
            className={`w-full flex max-[950px]:flex-col gap-16 max-[950px]:gap-4 ${
              item.reverse && "flex-row-reverse"
            }`}
          >
            <div className="w-1/2 max-[950px]:w-full">
              <img
                src={item.image}
                alt={item.text1}
                className="h-[420px] max-md:h-[300px] max-sm:h-[240px] w-full object-cover"
              />
            </div>
            <div className="w-1/2 max-[950px]:w-full">
              <p
                className={`${item.textColor} font-clashDisplay mb-6 max-md:mb-3 text-bombay text-[72px] max-lg:text-[62px] max-md:text-[50px] max-sm:text-[32px] font-semibold`}
              >
                0{item.id}
              </p>
              <p className="text-white text-[40px] max-lg:text-[32px] max-md:text-[24px] font-semibold">
                {item.text1}
              </p>
              <p className="text-white text-[40px] max-lg:text-[32px] max-md:text-[24px] font-semibold">
                {item.text2}
              </p>
              <p className="text-silver text-[24px] max-lg:text-[20px] max-md:text-[14px] font-semibold">
                {item.paragraph}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToLearn;
