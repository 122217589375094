import { ThumbsUp } from "assets/images";
import { TWAGreenArrowRightIcon } from "assets/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { programs } from "utils/data";

const JourneyExcellence = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-citrineWhite py-8 px-16 max-md:px-6 flex flex-col items-center">
      <p className="text-[36px] max-md:text-[23px] max-sm:text-[15px]">
        Discover Essential Courses
      </p>
      <div className="relative mb-12">
        <p className="font-clashDisplay whitespace-nowrap text-[60px] max-[919px]:text-[45px] max-[730px]:text-[30px] max-sm:text-[20px] font-semibold">
          Your Journey to Excellence
        </p>
        <img
          className="absolute -right-[55px] top-0 max-[919px]:-top-[10px] max-[919px]:-right-[50px] max-[730px]:-right-[25px] max-[730px]:w-[30px] max-[730px]:h-[30px]"
          src={ThumbsUp}
          alt="Journey to Excellence"
        />
      </div>
      <div className="w-[90%] px-8 max-md:px-6 max-[1280px]:flex-col max-[1280px]:items-center flex justify-between mb-4">
        {programs.slice(0, 2).map((program, idx) => (
          <div
            key={idx}
            className={`min-w-[294px] ${program.bgColor} border ${program.border} py-4 w-[450px] max-sm:w-fit mb-12 ${program.shadow}`}
          >
            <div className={`border-b border-black pb-2 px-8 max-sm:px-4`}>
              <img
                src={program.icon}
                alt="Design programs"
                className="max-sm:w-[30px]"
              />
            </div>
            <div className="px-8 py-6 max-sm:px-4 flex flex-col gap-[24px] h-full">
              <p className="text-[24px] max-sm:text-[14px] font-medium">
                {program.title}
              </p>
              <p className="text-[18px] max-sm:text-[12px]">{program.desc}</p>
              <div
                onClick={() =>
                  navigate("/programs", { state: { type: program.type } })
                }
                className="cursor-pointer flex gap-2 items-center border-b border-elm w-fit"
              >
                <p className="text-elm text-[20px] max-sm:text-[12px]">
                  Check out the one for you
                </p>
                <TWAGreenArrowRightIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="px-8 max-md:px-6 w-full flex justify-center">
        {programs.slice(2).map((program, idx) => (
          <div
            key={idx}
            className={`${program.bgColor} border ${program.border} py-4 w-[450px] max-sm:w-[350px] ${program.shadow}`}
          >
            <div className="border-b border-white pb-2 px-8 max-sm:px-4">
              <img
                src={program.icon}
                alt="Design programs"
                className="max-sm:w-[30px]"
              />
            </div>
            <div className="text-white px-8 py-6 max-sm:px-4 flex flex-col gap-[24px] h-full">
              <p className="text-[24px] max-sm:text-[14px] font-medium">
                {program.title}
              </p>
              <p className="text-[18px] max-sm:text-[12px]">{program.desc}</p>
              <div
                onClick={() =>
                  navigate("/programs", { state: { type: program.type } })
                }
                className="cursor-pointer flex gap-2 items-center border-b border-elm w-fit"
              >
                <p className="text-elm text-[20px] max-sm:text-[12px]">
                  Check out the one for you
                </p>
                <TWAGreenArrowRightIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JourneyExcellence;
