import { Founder, PlayBtn } from "assets/images";
import { TWADropdownIcon, TWAGreenArrowRightIcon } from "assets/svgs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "shared/Logo";
import WaitlistBtn from "shared/WaitlistBtn";

const HowItWorksContents = () => {
  const [onHover, setOnHover] = useState(false);

  const navigate = useNavigate();

  const schoolPrograms = [
    {
      title: "User Experience Interface Design",
      type: "design",
      subtype: "uiux",
    },
    {
      title: "Graphics Design",
      type: "design",
      subtype: "graphics",
    },
    {
      title: "Frontend Web Development",
      type: "engineering",
      subtype: "frontend",
    },
    {
      title: "Backend Web Development",
      type: "engineering",
      subtype: "backend",
    },
    {
      title: "Mobile App Development",
      type: "engineering",
      subtype: "mobile",
    },
    {
      title: "Data Analytics",
      type: "data",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="px-16 max-md:px-6 py-8">
      <div className="flex justify-between items-end">
        <Logo inverted />
        <div
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className="relative z-50 cursor-pointer flex items-center gap-2"
        >
          <p>Schools</p>
          <TWADropdownIcon className="w-4 h-4 fill-black stroke-black" />
          <div
            className={`absolute top-[24px] rounded-lg shadow-lg right-0 bg-mineShaft p-12 max-sm:py-6 px-6 ${
              onHover ? "block" : "hidden"
            }`}
          >
            {schoolPrograms.map((schoolProgram, idx) => (
              <div
                key={idx}
                className="w-[calc(100vw-180px)] text-white max-md:w-[calc(100vw-100px)] pb-4 mb-6 border-[#B4AEAE] border-b"
              >
                <div
                  onClick={() =>
                    navigate("/programs", {
                      state: {
                        type: schoolProgram.type,
                        subtype: schoolProgram.subtype,
                      },
                    })
                  }
                  className="cursor-pointer w-full flex justify-between items-center"
                >
                  <p className="text-[16px] max-sm:text-[12px] whitespace-nowrap">
                    {schoolProgram.title}
                  </p>
                  <TWAGreenArrowRightIcon className="fill-white stroke-white max-sm:w-4 max-sm:h-4" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-8 my-16">
        <p className="text-[82px] max-md:text-[60px] max-sm:text-[32px] font-bold font-clashDisplay">
          How It Works
        </p>
        <div className="relative w-full bg-founder bg-no-repeat bg-cover bg-center flex justify-center items-end rounded-[20px]">
          <img
            src={Founder}
            alt="How it works rounded-[20px]"
            className="max-md:w-[250px] object-contain"
          />
          <div className="absolute bottom-4 flex flex-col gap-6 items-center text-white">
            <img
              src={PlayBtn}
              alt="Watch how we work"
              className="w-[100px] h-[100px] max-md:w-[50px] max-md:h-[50px] cursor-pointer"
            />
            <p className="font-clashDisplay text-[40px] max-md:text-[20px] font-medium">
              Asikhia Iseoluwa
            </p>
            <p className="text-[32px] max-md:text-[16px]">
              Founder & CEO Techwave Academy
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <div className="w-[257px] h-[85px] text-center">
          <WaitlistBtn />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksContents;
