import { DataDrivenImage } from "assets/images";
import { TWADropdownIcon, TWAIconBlueArrowUp } from "assets/svgs";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import WaitlistBtn from "shared/WaitlistBtn";

const DataAnalytics = () => {
  const [viewDesc, setViewDesc] = useState(true);
  const [select, setSelect] = useState(null);

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  // const handleNavigate = () => {
  //   let path = "/join-waitlist";
  //   navigate(path, {
  //     state: {
  //       course: "Data Analytics",
  //       plan: select,
  //     },
  //   });
  // };

  const toLearnList = [
    {
      title: "Data Collection & Preparation",
      body: "Gain proficiency in gathering, cleaning, and organizing data for analysis.",
    },
    {
      title: "Statistical Analysis",
      body: "Explore foundational statistical concepts to derive valuable insights from datasets.",
    },
    {
      title: "Data Visualization",
      body: "Learn to create compelling visualizations that effectively communicate your findings.",
    },
    {
      title: "Introduction to Machine Learning",
      body: "Understand the basics of machine learning and how it's applied in data analysis.",
    },
    {
      title: "Real-world Applications",
      body: "Apply your skills to real-world scenarios, using data to solve practical problems.",
    },
  ];

  return (
    <div>
      <div className="max-[610px]:flex-wrap flex justify-center mb-12">
        <div className="w-1/2 max-[610px]:w-full pr-24 max-[850px]:pr-6 flex flex-col justify-around gap-8">
          <p className="text-[24px] max-[740px]:text-[12px]">
            Duration: <span className="font-semibold">4 months</span>
          </p>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-persianBlue">Pricing</p>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "monthly"}
                    value={"monthly"}
                    name="monthly-plan"
                  />
                  <p className="text-[20px] max-md:text-[14px] font-medium">
                    <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                      Monthly:
                    </span>{" "}
                    N20,000
                  </p>
                </label>
              </div>
              <div className="">
                <label className={`flex cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "one-time"}
                    value={"one-time"}
                    name="one-time"
                  />
                  <div className="flex items-center flex-wrap gap-x-4">
                    <p className="text-[20px] whitespace-nowrap max-md:text-[14px] font-medium">
                      <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                        One Time:
                      </span>{" "}
                      N65,000
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="w-fit max-md:w-full">
            <WaitlistBtn />
          </div>
        </div>
        <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8 max-[610px]:hidden">
          <img
            className="w-full h-full object-cover"
            src={DataDrivenImage}
            alt="Our Data Analytics course"
          />
        </div>
      </div>
      <div>
        <div
          onClick={() => setViewDesc(!viewDesc)}
          className="cursor-pointer flex items-center gap-2 mb-8"
        >
          <p className="text-persianBlue text-[24px] max-md:text-[14px]">
            View Course Description
          </p>
          {viewDesc ? (
            <TWAIconBlueArrowUp className="max-md:w-6 max-md:h-6 w-8 h-8" />
          ) : (
            <TWADropdownIcon className="fill-persianBlue stroke-persianBlue max-md:w-6 max-md:h-6 w-8 h-8" />
          )}
        </div>
        <div className={`${viewDesc ? "block" : "hidden"}`}>
          <div className="w-4/5 max-md:w-full mb-8">
            <p className="text-[24px] max-md:text-[14px] font-normal leading-8 min-md:leading-10">
              In this transformative course, you'll explore the intricacies of
              data analytics, uncovering the techniques and methodologies that
              guide successful data interpretation. From collecting and cleaning
              data to drawing meaningful conclusions, this program covers the
              entire data analytics lifecycle.
            </p>
          </div>
          <div>
            <p className="text-[24px] max-md:text-[14px] mb-4">
              What You'll Learn:
            </p>
            <ul className="list-disc">
              {toLearnList.map((item, idx) => (
                <li key={idx} className="mb-8">
                  <p className="text-[24px] max-md:text-[14px] font-light">
                    <span className="font-medium">{item.title}: </span>
                    {item.body}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 max-md:w-full mb-16">
            <p className="text-[24px] max-md:text-[14px] font-light">
              <span className="font-medium">Outcome: </span>By completing this
              course, you'll be equipped to analyze data, draw meaningful
              conclusions, and present insights effectively. Whether you're
              venturing into data analytics for the first time or aiming to
              enhance your existing skills, this course will empower you to
              harness the potential of data in the digital age.
            </p>
          </div>
          <div className="w-fit mb-8">
            <WaitlistBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAnalytics;
