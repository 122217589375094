import React, { useEffect, useState } from "react";
import UserInterface from "./UserInterface";
import GraphicsDesign from "./GraphicsDesign";
import { useLocation } from "react-router-dom";

const Courses = () => {
  const [activeCourse, setActiveCourse] = useState(0);

  const location = useLocation();

  useEffect(() => {
    location?.state?.subtype === "graphics"
      ? setActiveCourse(1)
      : setActiveCourse(0);
  }, [location?.state?.subtype]);

  const coursesArr = [
    {
      title: "User Experience Interface Design",
      component: <UserInterface />,
    },
    { title: "Graphics Design", component: <GraphicsDesign /> },
  ];

  return (
    <div>
      <p className="text-[32px] text-center max-sm:text-[24px] mb-8 max-sm:mb-4 text-mineShaft font-medium">
        Courses
      </p>
      <div className="flex justify-center overflow-x-auto mb-12 max-md:mb-6">
        <div className="max-md:w-full bg-wildSand rounded-[48px] flex items-center gap-2 overflow-x-auto py-1 px-2">
          {coursesArr.map((link, idx) => (
            <div
              key={idx}
              onClick={() => setActiveCourse(idx)}
              className={`cursor-pointer ${
                activeCourse === idx && "bg-elm text-white"
              } rounded-[48px] px-4 py-3 flex items-center justify-center`}
            >
              <p className="text-[16px] max-md:text-[12px] pb-1 whitespace-nowrap">
                {link.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>{coursesArr[activeCourse].component}</div>
    </div>
  );
};

export default Courses;
