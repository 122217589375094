import React, { useEffect } from "react";
import Banner from "./Banner";
import Partnership from "./Partnership";
import Hello from "./Hello";
import JourneyExcellence from "./JourneyExcellence";
import LearnAnywhere from "./LearnAnywhere";
import Mentor from "./Mentor";
import Footer from "./Footer";
import Roadmap from "./Roadmap";
import HowToLearn from "./HowToLearn";

const HomepageContent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Banner />
      <Partnership />
      <Hello />
      <JourneyExcellence />
      <HowToLearn />
      <LearnAnywhere />
      <Roadmap />
      <Mentor />
      <Footer />
    </div>
  );
};

export default HomepageContent;
