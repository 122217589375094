import { TWARoadmap, TWARoadmapDesktop } from "assets/images";
import React from "react";
import { Link } from "react-router-dom";

const Roadmap = () => {
  return (
    <div className="py-16 px-16 max-md:py-10 max-md:px-6 flex flex-col items-center">
      <div className="flex justify-center max-md:mb-8">
        <p className="font-medium text-[48px] max-md:text-[24px]">
          Our Roadmap
        </p>
      </div>
      <img
        src={TWARoadmapDesktop}
        alt="application details"
        className="w-full h-full object-cover max-md:hidden"
      />
      <img
        src={TWARoadmap}
        alt="application details"
        className="w-full h-full object-cover min-[768px]:hidden"
      />
      <div className="lex justify-center mt-6">
        <Link to={"/programs"}>
          <div className="bg-elm flex justify-center items-center gap-2 px-12 py-6 max-sm:px-6 max-sm:py-3">
            <p className="text-[16px] max-sm:text-[12px] text-white">
              Register Now
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Roadmap;
