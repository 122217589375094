import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { TECHWAVLOGO } from "assets/images";

const Logo = ({ inverted }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.5 }}
      className={`w-fit relative ${inverted && "text-black"} `}
    >
      <Link to="/">
        <div className="flex gap-1">
          <div>
            <img
              src={TECHWAVLOGO}
              alt="from novice to tech virtuoso"
              className="w-[45px] h-[45px]"
            />
          </div>
          <div>
            <p>Techwave</p>
            <p
              className={`relative bottom-1 right-0 text-[14px] flex justify-end`}
            >
              Academy
            </p>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default Logo;
