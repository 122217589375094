import React, { useState } from "react";
import Logo from "../../shared/Logo";
import {
  TWAArrowUpIcon,
  TWADropdownIcon,
  TWAGreenArrowRightIcon,
  TWAVideoOctagonIcon,
} from "assets/svgs";
import {
  Ellipse16,
  Ellipse17,
  Ellipse18,
  Ellipse19,
  Ellipse20,
  Ellipse21,
  Ellipse22,
} from "assets/images";
import { Link, useNavigate } from "react-router-dom";

const Banner = () => {
  const [onHover, setOnHover] = useState(false);

  const navigate = useNavigate();

  const schoolPrograms = [
    {
      title: "User Experience Interface Design",
      type: "design",
      subtype: "uiux",
    },
    {
      title: "Graphics Design",
      type: "design",
      subtype: "graphics",
    },
    {
      title: "Frontend Web Development",
      type: "engineering",
      subtype: "frontend",
    },
    {
      title: "Backend Web Development",
      type: "engineering",
      subtype: "backend",
    },
    {
      title: "Mobile App Development",
      type: "engineering",
      subtype: "mobile",
    },
    {
      title: "Data Analytics",
      type: "data",
    },
  ];

  return (
    <div className="text-white bg-home-banner bg-cover bg-no-repeat">
      <div className="px-16 max-md:px-6 top-0 py-8 flex justify-between items-end">
        <Logo />
        <div
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className="relative cursor-pointer flex items-center gap-2"
        >
          <p>Schools</p>
          <TWADropdownIcon className="w-4 h-4" />
          <div
            className={`absolute top-[24px] rounded-lg shadow-lg right-0 bg-mineShaft p-12 max-sm:py-6 px-6 ${
              onHover ? "block" : "hidden"
            }`}
          >
            {schoolPrograms.map((schoolProgram, idx) => (
              <div
                key={idx}
                className="w-[calc(100vw-180px)] max-md:w-[calc(100vw-100px)] pb-4 mb-6 border-[#B4AEAE] border-b"
              >
                <div
                  onClick={() =>
                    navigate("/programs", {
                      state: {
                        type: schoolProgram.type,
                        subtype: schoolProgram.subtype,
                      },
                    })
                  }
                  className="cursor-pointer w-full flex justify-between items-center"
                >
                  <p className="text-[16px] max-sm:text-[12px] whitespace-nowrap">
                    {schoolProgram.title}
                  </p>
                  <TWAGreenArrowRightIcon className="fill-white stroke-white max-sm:w-4 max-sm:h-4" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-8 px-16 max-md:px-6 bg-banner-dashed-lines bg-contain bg-no-repeat max-md:bg-[length:100%_80%] max-md:bg-[center_top_3rem] bg-[center_top_5rem] flex flex-col items-center mb-12 max-md:mb-6">
        <p className="banner font-clashDisplay text-[81px] max-[1260px]:text-[60px] max-[1000px]:text-[50px] max-[800px]:text-[40px] max-[680px]:text-[20px] font-semibold">
          From Novice to Tech Virtuoso
        </p>
        <div></div>
        <div className="w-3/5 mt-3 max-md:w-full mb-16">
          <p className="text-[20px] max-md:text-[14px] text-center">
            We empower you to not only embrace those skills but also guide you
            in setting industry benchmarks.
          </p>
        </div>
        <div className="flex flex-col items-center gap-12">
          <div className="grid grid-cols-4 gap-48 max-[1260px]:gap-24 max-[880px]:gap-12">
            <img
              src={Ellipse16}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
            <img
              src={Ellipse17}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
            <img
              src={Ellipse18}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
            <img
              src={Ellipse19}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
          </div>
          <div className="grid grid-cols-3 gap-48 max-[1260px]:gap-24 max-[880px]:gap-12">
            <img
              src={Ellipse20}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
            <img
              src={Ellipse21}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
            <img
              src={Ellipse22}
              alt="future talents"
              className="max-md:w-[90px] max-md:h-[90px] max-sm:w-[45px] max-sm:h-[45px]"
            />
          </div>
        </div>
      </div>
      <div className="pb-24 px-16 max-md:px-6 flex gap-4 justify-center">
        <Link to={"/programs"}>
          <div className="max-sm:h-[40px] bg-elm rounded-lg flex justify-center items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
            <p className="text-[20px] max-sm:text-[12px]">Register Now</p>
            <TWAArrowUpIcon className="max-sm:w-4 max-sm:h-4" />
          </div>
        </Link>
        <Link to={"/"}>
          <div className="max-sm:h-[40px] flex gap-3 border rounded-lg border-white">
            <div className="bg-white rounded-tl-lg rounded-bl-lg w-1/3 py-4 px-6 max-sm:py-2 max-sm:px-3 flex justify-center items-center">
              <TWAVideoOctagonIcon className="max-sm:w-4 max-sm:h-4" />
            </div>
            <div className="py-4 pr-6 max-sm:py-2 max-sm:pr-3 flex items-center">
              <p className="text-white text-[20px] max-sm:text-[12px] whitespace-nowrap">
                How it works
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Banner;
