import React from "react";
import Banner from "./Banner";
import Courses from "./Courses";

const Design = () => {
  return (
    <div>
      <Banner />
      <Courses />
    </div>
  );
};

export default Design;
