import { TWAPartyCap } from "assets/images";

const Banner = () => {
  return (
    <div className="mb-12 max-md:mb-6">
      <p className="text-[40px] max-sm:text-[16px]">
        Get Certified from our Engineering Programs:
      </p>
      <div className="mt-10 mb-5">
        <div className="flex items-center">
          <p className="font-clashDisplay text-[48px] max-sm:text-[20px] max-md:text-[32px] font-semibold">
            Step into the World of Coding Excellence
          </p>
          <div className="max-[600px]:h-[60px] max-[600px]:w-[60px]">
            <img
              className="h-full w-full"
              src={TWAPartyCap}
              alt="Excellence in every byte"
            />
          </div>
        </div>
      </div>
      <p className="text-mineShaft text-[24px] w-4/5 max-sm:text-[16px] max-sm:w-full">
        Ready to dive into the heart of technology? Our coding program is
        designed to transform your curiosity into expertise. Learn the
        fundamentals of programming, explore diverse languages, and emerge as a
        confident coder who's ready to tackle real-world challenges.
      </p>
    </div>
  );
};

export default Banner;
