import { TWAArrowUpIcon } from "assets/svgs";
import React from "react";
import { Link } from "react-router-dom";

const Mentor = () => {
  return (
    <div className="h-[500px] max-md:h-[300px] bg-mentor bg-cover bg-no-repeat text-white flex justify-center">
      <div className="flex flex-col items-center justify-center w-3/5 max-md:w-4/5 max-sm:w-full max-sm:px-8 gap-8">
        <p className="text-[48px] max-lg:text-[40px] max-md:text-[32px] max-sm:text-[20px] font-medium text-center">
          Get an internship opportunity after the learning process
        </p>
        <Link to={"/programs"}>
          <div className="max-sm:h-[40px] bg-transparent rounded-lg flex justify-center border-2 border-white items-center gap-2 px-8 py-4 max-sm:px-4 max-sm:py-2">
            <p className="text-[20px] max-sm:text-[12px] font-medium">
              Register Now
            </p>
            <TWAArrowUpIcon className="max-sm:w-4 max-sm:h-4 fill-white stroke-white" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Mentor;
