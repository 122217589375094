import React, { useState } from "react";
import Logo from "shared/Logo";
import { NavLink } from "react-router-dom";
import {
  TWAIconFacebook,
  TWAIconInstagram,
  TWAIconLinkedIn,
  TWAIconSMS,
  TWAIconTelephone,
  TWAIconTwitter,
} from "assets/svgs";
import { subscribe } from "api/subscribers";
import { toast } from "react-toastify";

const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { status } = await subscribe({ email });
      if (status === 200) {
        toast.success(`Thank you for subscribing to our newsletter!`);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setEmail("");
  };

  return (
    <div className="text-white px-24 py-16 border-t border-[#181818] max-md:px-12 max-sm:px-6 bg-black">
      <div className="pb-8 border-b border-[#474A49] flex justify-between items-center max-[560px]:items-start max-[560px]:gap-12 gap-4 max-[560px]:flex-col">
        <Logo />
        <form onSubmit={handleSubmit}>
          <p className="font-clashDisplay text-[20px] max-sm:text-[16px]">
            Subscribe to our news letter and events
          </p>
          <div className="flex w-[400px] h-[50px] max-[560px]:w-full">
            <button type="submit">
              <div className="h-[50px] bg-elm rounded-l-lg flex justify-center items-center px-8 py-4 max-sm:px-4 max-sm:py-2">
                <p className="font-clashDisplay text-[16px] max-sm:text-[12px]">
                  {isLoading ? "Loading..." : "Subscribe"}
                </p>
              </div>
            </button>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              type="email"
              className="pl-4 w-full bg-black border-y border-r rounded-tr-lg rounded-br-lg border-white placeholder:font-clashDisplay placeholder:text-[14px] placeholder:text-[#9F9C9C] outline-none"
              placeholder="Your email address"
            />
          </div>
        </form>
      </div>
      <div className="servicesCard mt-8 gap-12">
        <div className="flex flex-col gap-4">
          <p className="text-[16px] text-nobel pb-3">Pages</p>
          <NavLink to="/programs">Schools</NavLink>
          <NavLink to="/">Community</NavLink>
          <NavLink to="/programs">Company</NavLink>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-[16px] text-nobel">Address</p>
          <p>Gbagada, Lagos Nigeria.</p>
          <div className="flex gap-2 mb-2">
            <TWAIconTelephone className="w-5 h-5" />
            <p className="underline text-[14px]">+234 8068097620</p>
          </div>
          <div className="flex gap-2">
            <TWAIconSMS className="w-5 h-5" />
            <p className="text-[14px]">contactus@techwaveteam.com </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <p className="text-[16px] text-nobel pb-3">Social Links</p>
          <div className="flex items-center gap-8">
            <NavLink to="https://www.facebook.com/profile.php?id=61551454241866&mibextid=LQQJ4d">
              <TWAIconFacebook />
            </NavLink>
            <NavLink to="https://x.com/techwaveacademy">
              <TWAIconTwitter />
            </NavLink>
            <NavLink to="https://www.linkedin.com/company/techwave-academy/">
              <TWAIconLinkedIn />
            </NavLink>
            <NavLink to="https://instagram.com/techwaveacademy?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr">
              <TWAIconInstagram />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
