import { BackendImage } from "assets/images";
import { TWADropdownIcon, TWAIconBlueArrowUp } from "assets/svgs";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import WaitlistBtn from "shared/WaitlistBtn";

const Backend = () => {
  const [viewDesc, setViewDesc] = useState(true);
  const [select, setSelect] = useState(null);

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  // const handleNavigate = () => {
  //   let path = "/join-waitlist";
  //   navigate(path, {
  //     state: {
  //       course: "Backend Web Development",
  //       plan: select,
  //     },
  //   });
  // };

  const toLearnList = [
    {
      title: "Server-side Programming",
      body: "Master server-side languages like Node.js or Python to build scalable and efficient applications.",
    },
    {
      title: "Database Management",
      body: "Learn to design and manage databases, enabling efficient data storage and retrieval.",
    },
    {
      title: "API Development",
      body: "Explore the creation of APIs that allow smooth communication between frontend and backend components.",
    },
    {
      title: "Authentication & Security",
      body: "Understand best practices for user authentication, data security, and protection against vulnerabilities.",
    },
    {
      title: "Version Control & Deployment",
      body: "Discover version control systems and deployment strategies for maintaining and launching applications.",
    },
  ];

  return (
    <div>
      <div className="w-4/5 max-md:w-full mb-16 max-md:mb-8">
        <p className="text-[24px] max-md:text-[12px]">
          Participants will develop the abilities to create efficient and robust
          digital infrastructures that support seamless user experiences,
          focusing on backend development.
        </p>
      </div>
      <div className="max-[610px]:flex-wrap flex justify-center mb-12">
        <div className="w-1/2 max-[610px]:w-full pr-24 max-[850px]:pr-6 flex flex-col justify-around gap-8">
          <p className="text-[24px] max-[740px]:text-[12px]">
            Duration: <span className="font-semibold">4 months</span>
          </p>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-persianBlue">Pricing</p>
              <div className="">
                <label className={`flex cursor-pointer items-center gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "monthly"}
                    value={"monthly"}
                    name="monthly-plan"
                  />
                  <p className="text-[20px] max-md:text-[14px] font-medium">
                    <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                      Monthly:
                    </span>{" "}
                    N20,000
                  </p>
                </label>
              </div>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "one-time"}
                    value={"one-time"}
                    name="one-time"
                  />
                  <div className="flex items-center flex-wrap gap-x-4">
                    <p className="text-[20px] whitespace-nowrap max-md:text-[14px] font-medium">
                      <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                        One Time:
                      </span>{" "}
                      N65,000
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="w-fit max-md:w-full">
            <WaitlistBtn />
          </div>
        </div>
        <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8 max-[610px]:hidden">
          <img
            className="w-full h-full object-cover"
            src={BackendImage}
            alt="Our backend course"
          />
        </div>
      </div>
      <div>
        <div
          onClick={() => setViewDesc(!viewDesc)}
          className="cursor-pointer flex items-center gap-2 mb-8"
        >
          <p className="text-persianBlue text-[24px] max-md:text-[14px]">
            View Course Description
          </p>
          {viewDesc ? (
            <TWAIconBlueArrowUp className="max-md:w-6 max-md:h-6 w-8 h-8" />
          ) : (
            <TWADropdownIcon className="fill-persianBlue stroke-persianBlue max-md:w-6 max-md:h-6 w-8 h-8" />
          )}
        </div>
        <div className={`${viewDesc ? "block" : "hidden"}`}>
          <div className="w-4/5 max-md:w-full mb-8">
            <p className="text-[24px] max-md:text-[14px] font-normal leading-8 min-md:leading-10">
              In this comprehensive course, you'll delve into the core concepts
              of backend development, exploring the tools and methodologies that
              enable the functionality of websites and applications. From
              crafting robust APIs to managing databases, this program covers
              the essential aspects of backend development.
            </p>
          </div>
          <div>
            <p className="text-[24px] max-md:text-[14px] mb-4">
              What You'll Learn:
            </p>
            <ul className="list-disc">
              {toLearnList.map((item, idx) => (
                <li key={idx} className="mb-8">
                  <p className="text-[24px] max-md:text-[14px] font-light">
                    <span className="font-medium">{item.title}: </span>
                    {item.body}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 max-md:w-full mb-16">
            <p className="text-[24px] max-md:text-[14px] font-light">
              <span className="font-medium">Outcome: </span>Upon completing this
              course, you'll possess the skills to design and develop robust
              backend systems that power the digital landscape. Your newfound
              expertise will empower you to contribute to the creation of
              efficient, secure, and user-focused web applications.
            </p>
          </div>
          <div className="w-fit mb-8">
            <WaitlistBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
