import { FrontendImage } from "assets/images";
import { TWADropdownIcon, TWAIconBlueArrowUp } from "assets/svgs";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import WaitlistBtn from "shared/WaitlistBtn";

const Frontend = () => {
  const [viewDesc, setViewDesc] = useState(true);
  const [select, setSelect] = useState(null);

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setSelect(e.target.value);
  };

  // const handleNavigate = () => {
  //   let path = "/join-waitlist";
  //   navigate(path, {
  //     state: {
  //       course: "Frontend Web Development",
  //       plan: select,
  //     },
  //   });
  // };

  const toLearnList = [
    {
      title: "HTML & CSS Fundamentals",
      body: "Master the building blocks of web design, crafting structured content and styling elements with precision.",
    },
    {
      title: "Responsive Design",
      body: "Learn to create dynamic and adaptable layouts that function flawlessly across different devices and screen sizes.",
    },
    {
      title: "Interactive Elements",
      body: " Dive into JavaScript, enabling you to add interactive features and animations to your web projects.",
    },
    {
      title: "Version Control with Git",
      body: "Explore version control concepts using Git, a crucial skill for collaborative coding.",
    },
    {
      title: "Web Performance Optimization",
      body: "Understand techniques to optimize website performance for enhanced user experiences.",
    },
  ];

  return (
    <div>
      <div className="w-4/5 max-md:w-full mb-16 max-md:mb-8">
        <p className="text-[24px] max-md:text-[12px]">
          Learners will acquire the expertise to craft smooth and user-centered
          digital interfaces that enthrall and involve users, through frontend
          development.
        </p>
      </div>
      <div className="max-[610px]:flex-wrap flex justify-center mb-12">
        <div className="w-1/2 max-[610px]:w-full pr-24 max-[850px]:pr-6 flex flex-col justify-around gap-8">
          <p className="text-[24px] max-[740px]:text-[12px]">
            Duration: <span className="font-semibold">4 months</span>
          </p>
          <div>
            <div className="flex flex-col gap-6">
              <p className="text-persianBlue">Pricing</p>
              <div className="">
                <label className={`flex items-center cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "monthly"}
                    value={"monthly"}
                    name="monthly-plan"
                  />
                  <p className="text-[20px] max-md:text-[14px] font-medium">
                    <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                      Monthly:
                    </span>{" "}
                    N20,000
                  </p>
                </label>
              </div>
              <div className="">
                <label className={`flex cursor-pointer gap-3`}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    checked={select === "one-time"}
                    value={"one-time"}
                    name="one-time"
                  />
                  <div className="flex items-center flex-wrap gap-x-4">
                    <p className="text-[20px] whitespace-nowrap max-md:text-[14px] font-medium">
                      <span className="w-[120px] max-md:w-[90px] inline-block text-[20px] max-md:text-[14px]">
                        One Time:
                      </span>{" "}
                      N65,000
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="w-fit max-md:w-full">
            <WaitlistBtn />
          </div>
        </div>
        <div className="w-1/2 max-[610px]:w-4/5 max-[610px]:pb-8 max-[610px]:hidden">
          <img
            className="w-full h-full object-cover"
            src={FrontendImage}
            alt="Our frontend course"
          />
        </div>
      </div>
      <div>
        <div
          onClick={() => setViewDesc(!viewDesc)}
          className="cursor-pointer flex items-center gap-2 mb-8"
        >
          <p className="text-persianBlue text-[24px] max-md:text-[14px]">
            View Course Description
          </p>
          {viewDesc ? (
            <TWAIconBlueArrowUp className="max-md:w-6 max-md:h-6 w-8 h-8" />
          ) : (
            <TWADropdownIcon className="fill-persianBlue stroke-persianBlue max-md:w-6 max-md:h-6 w-8 h-8" />
          )}
        </div>
        <div className={`${viewDesc ? "block" : "hidden"}`}>
          <div className="w-4/5 max-md:w-full mb-8">
            <p className="text-[24px] max-md:text-[14px] font-normal leading-8 min-md:leading-10">
              In this comprehensive course, you'll delve into the core concepts
              of frontend development, exploring the tools and techniques that
              shape modern web design. From translating designs into interactive
              layouts to ensuring seamless responsiveness, this program covers
              every aspect of frontend development.
            </p>
          </div>
          <div>
            <p className="text-[24px] max-md:text-[14px] mb-4">
              What You'll Learn:
            </p>
            <ul className="list-disc">
              {toLearnList.map((item, idx) => (
                <li key={idx} className="mb-8">
                  <p className="text-[24px] max-md:text-[14px] font-light">
                    <span className="font-medium">{item.title}: </span>
                    {item.body}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-4/5 max-md:w-full mb-16">
            <p className="text-[24px] max-md:text-[14px] font-light">
              <span className="font-medium">Outcome: </span>Upon completing this
              course, you'll have the proficiency to design and develop
              captivating frontend interfaces. Your newfound skills will empower
              you to create web experiences that are not only aesthetically
              pleasing but also user-friendly and responsive.
            </p>
          </div>
          <div className="w-fit mb-8">
            <WaitlistBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frontend;
